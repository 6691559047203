import React from 'react';
import MoonIcon from '../images/icons/moon.svg';
import SunIcon from '../images/icons/sun.svg';

import useDarkMode from 'use-dark-mode';

import './theme-toggle.scss';

export default function ThemeToggle() {
  const darkMode = useDarkMode(false);

  return (
    <label className="theme-toggler" htmlFor="toggle">
      <div className={`toggle ${darkMode.value ? 'enabled' : 'disabled'}`}>
        <span className="hidden">
          {darkMode.value ? 'Enable Light Mode' : 'Enable Dark Mode'}
        </span>
        <div className="icons">
          <SunIcon className={`sun ${darkMode.value ? '' : 'active'}`} />
          <MoonIcon className={`moon ${darkMode.value ? 'active' : ''}`} />
        </div>
        <input
          id="toggle"
          name="toggle"
          type="checkbox"
          checked={darkMode.value}
          onChange={darkMode.toggle}
        />
      </div>
    </label>
  );
}
