import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, Link } from 'gatsby';

import './layout.scss';

import favicon from '../images/favicon.png';
// import ThemeToggle from './theme-toggle';
import ThemeToggle from './theme-toggle.js';
import useDarkMode from 'use-dark-mode';

const Layout = ({ children, footer, ...props }) => {
  let darkMode = useDarkMode();
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet title={data.site.siteMetadata.title}>
            <html lang="en" />
            <link rel="icon" href={favicon} />
          </Helmet>
          <header>
            <h1>nick freeman</h1>
          </header>
          <nav>
            <ul role="nav">
              <Link to="/" activeClassName="active">
                about_me
              </Link>
              <Link
                to="/projects"
                activeClassName="active"
                partiallyActive={true}
              >
                projects
              </Link>
              {/* <Link to="/cv" activeClassName="active">
              CV
            </Link> */}
              <Link
                to="/workshops"
                activeClassName="active"
                partiallyActive={true}
              >
                workshops
              </Link>
              <Link
                to="/writing"
                activeClassName="active"
                partiallyActive={true}
              >
                writing
              </Link>
              <Link to="/picks" activeClassName="active" partiallyActive={true}>
                picks
              </Link>
              <Link
                to="/contact"
                activeClassName="active"
                partiallyActive={true}
              >
                contact
              </Link>
              <a href="https://github.com/nikulis">github</a>
            </ul>
            <ThemeToggle />
          </nav>
          {children}
          <footer>
            {footer}
            <div style={{ marginBottom: '0.5em', textAlign: 'center' }}>
              <Link to="/">home</Link>&ensp;&middot;&ensp;
              <Link to="/contact">contact</Link>
            </div>
          </footer>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
